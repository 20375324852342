import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import store from "../store";
import { loadPatients } from "../actions";
import { filteredPatientsSelector } from "../selectors";
import { fetchPatients } from "../services/patient.service";

import PatientList from "./PatientList";
import PatientFilter from "./PatientFilter";
import ScrollTopButton from "./ScrollTopButton";

const PatientBrowser = ({
  user,
  filteredPatients,
  filterOptions: { activeFilters, nameFilter },
}) => {
  const [statusFilteredPatients, setStatusFilteredPatients] = useState([]);
  const deployEnvironment = process.env.REACT_APP_DEPLOY_ENVIRONMENT;

  useEffect(() => {
    const updateReduxStore = async () => {
      // update redux store on page load, this keeps the data fresh
      const patients = await fetchPatients();
      store.dispatch(loadPatients(patients));
    };

    updateReduxStore();
  }, []);

  useEffect(() => {
    if (nameFilter) {
      setStatusFilteredPatients(filteredPatients);
    } else {
      if (activeFilters.includes("signedOnly")) {
        const signedPatients = filteredPatients.filter(
          (patient) => patient.studyStatus === "Signed"
        );
        setStatusFilteredPatients(signedPatients);
      } else {
        if (activeFilters.includes("archivedStudies")) {
          const archivedPatients = filteredPatients.filter(
            (patient) => patient.studyStatus === "Archived"
          );
          setStatusFilteredPatients(archivedPatients);
        } else {
          if (
            user.attributes["custom:role"] === "external" ||
            deployEnvironment === "demo"
          ) {
            const externalPatients = filteredPatients.filter(
              (patient) => patient.studyStatus !== "Archived"
            );
            setStatusFilteredPatients(externalPatients);
          } else {
            const unsignedPatients = filteredPatients.filter(
              (patient) =>
                patient.studyStatus !== "Signed" &&
                patient.studyStatus !== "Archived"
            );
            setStatusFilteredPatients(unsignedPatients);
          }
        }
      }
    }
  }, [activeFilters, filteredPatients, nameFilter]);

  console.log("statusFiltered", statusFilteredPatients);

  // hide test reports from the Learner who has already opened the test report
  const learnerFilteredPatients =
    user.attributes["custom:role"] === "Educator"
      ? statusFilteredPatients
      : statusFilteredPatients.filter(
          (patient) =>
            !(
              patient.studyStatus === "Waiting To Be Read" &&
              patient.audit.find(
                (elem) =>
                  elem.accessType === "view" &&
                  elem.user === user.attributes.email &&
                  elem.role === "Learner"
              )
            )
        );

  console.log("learnerFiltered", learnerFilteredPatients);

  return (
    <div>
      <PatientFilter filteredPatients={learnerFilteredPatients} />
      <PatientList patients={learnerFilteredPatients} user={user} />
      <ScrollTopButton />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteredPatients: filteredPatientsSelector(state),
  filterOptions: state.filterOptions,
});

export default connect(mapStateToProps)(PatientBrowser);
