import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Gravatar from "react-gravatar";

import { Menu, MenuItem, MenuButton, MenuList, Box } from "@chakra-ui/react";
import { MdHome, MdPerson, MdExitToApp } from "react-icons/md";
import { FaRegChartBar, FaFax } from "react-icons/fa";

const deployEnvironment = process.env.REACT_APP_DEPLOY_ENVIRONMENT;

const AccountButton = ({ user, signOut }) => {
  const history = useHistory();

  function logOut() {
    history.push("/");
    signOut();
  }

  return (
    <>
      <Menu>
        <MenuButton>
          <Gravatar email={user.email || ""} style={{ borderRadius: "50%" }} />
        </MenuButton>
        <MenuList fontSize="16px" color={"black"} zIndex={3}>
          <MenuItem as={RouterLink} to="/">
            <Box w="2.5rem" mr="1rem">
              <MdHome />
            </Box>
            Home
          </MenuItem>
          <MenuItem as={RouterLink} to="/profile">
            <Box w="2.5rem" mr="1rem">
              <MdPerson />
            </Box>
            Profile {/* - {user.email} */}
          </MenuItem>
          {(deployEnvironment === "demo" ||
            (user["custom:role"] &&
              user["custom:role"] !== "external" &&
              user["custom:role"] !== "Learner")) && (
            <>
              <MenuItem
                display={{ base: "none", md: "flex" }}
                as={RouterLink}
                to="/audit"
              >
                <Box w="2.5rem" mr="1rem">
                  &#9878;
                </Box>
                Audit
              </MenuItem>
              <MenuItem
                display={{ base: "none", md: "flex" }}
                as={RouterLink}
                to="/faxStatus"
              >
                <Box w="2.5rem" mr="1rem">
                  <FaFax />
                </Box>
                Fax status
              </MenuItem>
              <MenuItem
                display={{ base: "none", md: "flex" }}
                as={RouterLink}
                to="/referringphysicians"
              >
                <Box w="2.5rem" mr="1rem">
                  <span role="img" aria-label="physicians icon">
                    👩‍⚕️👨‍⚕️
                  </span>
                </Box>
                Referring Physicians
              </MenuItem>
              <MenuItem
                display={{ base: "none", md: "flex" }}
                as={RouterLink}
                to="/reporttemplates"
              >
                <Box w="2.5rem" mr="1rem">
                  <span role="img" aria-label="paper icon">
                    📄
                  </span>
                </Box>
                Report Templates
              </MenuItem>
              <MenuItem
                display={{ base: "none", md: "flex" }}
                as={RouterLink}
                to="/normalvalues"
              >
                <Box w="2.5rem" mr="1rem">
                  <FaRegChartBar />
                </Box>
                Normative Values
              </MenuItem>
              <MenuItem
                display={{ base: "none", md: "flex" }}
                as={RouterLink}
                to="/macros"
              >
                <Box w="2.5rem" mr="1rem">
                  <FaRegChartBar />
                </Box>
                Macros
              </MenuItem>
            </>
          )}
          <MenuItem onClick={logOut}>
            <Box w="2.5rem" mr="1rem">
              <MdExitToApp />
            </Box>
            Signout
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default AccountButton;
