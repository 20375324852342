import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/tag";

import { removeOrAddItemFromArray } from "../utils/util";
import { updateFilter, activateFilterDialog } from "../actions";

const chipContent = {
  favouritesOnly: () => "Favourites",
  abnormalsOnly: () => "Abnormals",
  waitingToBeReadOnly: () => "Waiting To Be Read",
  inProgressOnly: () => "In progress",
  signedOnly: () => "Signed",
  todaysStudies: () => "Today's Studies",
  freeFormStudies: () => "Free Form",
  physiologicalStressStudies: () => "Physiological Stress",
  dobutamineStressStudies: () => "Dobutamine Stress",
  pediatricStudies: () => "Pediatric",
  faxfailedStudies: () => "Fax failed",
  archivedStudies: () => "Archived",
  amendedStudies: () => "Amended",
};

if (
  process.env.REACT_APP_DEPLOY_ENVIRONMENT === "sso" ||
  process.env.REACT_APP_DEPLOY_ENVIRONMENT === "CNEAT"
) {
  chipContent["goldReportStudies"] = () => "Test Reports";
  chipContent["testsInProgress"] = () => "Tests In Progress";
}

if (process.env.REACT_APP_DEPLOY_ENVIRONMENT === "mkmed") {
  chipContent["drAlRiyamiStudies"] = () => "Dr. Al Riyami's studies";
  chipContent["drZuberiStudies"] = () => "Dr. Zuberi's studies";
}

const FilterChips = ({ activateFilterDialog, updateFilter, filterOptions }) => {
  const { activeFilters } = filterOptions;

  return (
    <>
      {activeFilters.map((activeFilter) => {
        return (
          <Tag
            m=".5rem"
            key={activeFilter}
            borderRadius="full"
            variant="solid"
            colorScheme="gray"
          >
            <TagLabel>
              {chipContent[activeFilter.toString()](filterOptions)}
            </TagLabel>
            <TagCloseButton
              onClick={() =>
                updateFilter({
                  activeFilters: removeOrAddItemFromArray(
                    activeFilter,
                    activeFilters
                  ),
                })
              }
            />
          </Tag>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  filterOptions: state.filterOptions,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: bindActionCreators(updateFilter, dispatch),
  activateFilterDialog: bindActionCreators(activateFilterDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterChips);
