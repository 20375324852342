import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { GrInProgress, GrDocumentPdf } from "react-icons/gr";
import { StarIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import { FaHeart } from "react-icons/fa";

import { Avatar, Box, Button, Flex, Grid, IconButton } from "@chakra-ui/react";

import { updateFavourites } from "../actions";
import {
  currentPatientSelector,
  isFavouriteSelector,
  allGlassesSelector,
} from "../selectors";
import { customDateFormat, removeOrAddItemFromArray } from "../utils/util";

import {
  updateSeries,
  getOneSeries,
  createSeries,
} from "../services/form-api-service";
import { fetchPatients } from "../services/patient.service";
import store from "../store";
import { loadPatients } from "../actions";

const styles = {
  diagnosisList: {
    paddingLeft: 0,
  },
  card: {
    width: "20rem",
    margin: ".5rem",
    backgroundColor: "#fff",
  },
  actions: {
    justifyContent: "space-between",
  },
  title: {
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    fontWeight: "700",
  },
  readTitle: {
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    fontWeight: "350",
  },
  subHeader: {
    fontSize: 14,
    fontStyle: "italic",
    color: "#0000008A",
  },
  borderStyles: {
    borderRadius: "4px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),  0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
};

const deployEnvironment = process.env.REACT_APP_DEPLOY_ENVIRONMENT;
const deployASE = process.env.REACT_APP_PREMIUM_ASE;

const PatientItem = ({
  patient,
  displayStyle,
  favourite,
  favourites,
  updateFavourites,
  user,
}) => {
  const CustomCardHeader = () => {
    // https://thewebdev.info/2021/08/28/how-to-find-the-last-matching-object-in-array-of-objects-with-javascript/

    const reportViewedByThisUser = patient.audit.find(
      (elem) =>
        elem.accessType === "view" && elem.user === user.attributes.email
    );

    return (
      <Flex p="1rem" justify="flex-start" align="center">
        <Avatar
          name={patient.PatientName}
          style={{
            color:
              parseInt(patient.abnTotal) === 0
                ? "rgb(255, 255, 255)"
                : "rgba(1, 1, 1, .8)",
            backgroundColor:
              parseInt(patient.abnTotal) === 0
                ? "green"
                : parseInt(patient.abnTotal) === 2
                ? "red"
                : "orange",
          }}
        />
        <Flex pl="1rem" direction="column" width="80%">
          {reportViewedByThisUser ? (
            <h1 style={styles.readTitle}>{patient.PatientName}</h1>
          ) : (
            <h1 style={styles.title}>{patient.PatientName}</h1>
          )}
          <span style={styles.subHeader}>
            {user.attributes["custom:clinicName"] === "CNEAT"
              ? `Age: ${patient.PatientAge}`
              : `DoB: ${customDateFormat(patient.PatientBirthDate)}`}
            <br />
            Gender: {patient.PatientSex}
            <br />
            Series Date:{" "}
            {customDateFormat(
              !patient.SeriesDate ? patient.StudyDate : patient.SeriesDate
            )}
            <br />
            Study Type:{" "}
            {patient.ProtocolName?.toLowerCase().includes("stress")
              ? "Physiological Stress"
              : patient.ProtocolName}{" "}
            {patient.isContrastAgentUsed ? " (CONTRAST)" : ""}
            <br />
            Reading Physician: {patient.PhysiciansOfRecord}
            {deployEnvironment === "mkmed" && patient.StationName && <br />}
            {deployEnvironment === "mkmed" &&
              patient.StationName &&
              `Machine: ${patient.StationName}`}
          </span>
        </Flex>
      </Flex>
    );
  };

  // pathology only seen if any of the following 3 scenarios are true
  // 1) customer paying for ASE regardless of role
  // 2) is Learner and is not Gold Report
  // 3) is Educator or Cardiologist or Sonographer

  // ASE content only seen if any of the following 2 scenarios are true
  // 1) customer paying for ASE regardless of role
  // 2) is Educator or Cardiologist or Sonographer
  // this means Learners never see ASE content

  const CustomCardContent = () => {
    return (
      <Box p={displayStyle === "list" ? "1rem" : ".5rem 1rem"}>
        <ul style={{ ...styles.diagnosisList, padding: "auto 0" }}>
          Study Status: &nbsp;
          <span style={styles.diagnosisList}>{patient.studyStatus}</span>
          <br />
          {(deployASE === "true" ||
            (user.attributes["custom:role"] === "Learner" &&
              !patient.isGoldReport) ||
            user.attributes["custom:role"] === "Educator" ||
            user.attributes["custom:role"] === "Sonographer" ||
            user.attributes["custom:role"] === "Cardiologist") && (
            <>
              Pathology: &nbsp;
              <span style={styles.diagnosisList}>{patient.Pathology}</span>
              <br />
            </>
          )}
          {user?.attributes["custom:role"] === "Educator" &&
            patient.LearnerID && (
              <>
                LearnerID: &nbsp;
                <span style={styles.diagnosisList}>{patient.LearnerID}</span>
                <br />
              </>
            )}
          {(deployASE === "true" ||
            user.attributes["custom:role"] !== "Learner") && (
            <>
              Measurements:
              <span
                style={{
                  ...styles.diagnosisList,
                  color:
                    parseInt(patient.abnMeasure) === 1 ||
                    patient.EFSimpsonsQ !== "Normal"
                      ? "red"
                      : "green",
                }}
              >
                {" "}
                {/*            {parseInt(patient.abnMeasure) === 1 ? "Abnormal" : "Normal"}
                 */}
                {!patient.PatientSex || !patient.PatientBirthDate
                  ? "Missing Parameters"
                  : parseInt(patient.abnMeasure) === 1 ||
                    patient.EFSimpsonsQ !== "Normal"
                  ? "Abnormal"
                  : "Normal"}
              </span>
              <br />
              Systolic Function:
              <span
                style={{
                  ...styles.diagnosisList,
                  color: patient.EFSimpsonsQ !== "Normal" ? "red" : "green",
                }}
              >
                {" "}
                {patient.EFSimpsonsQ +
                  " " +
                  patient.EFSimpsons +
                  "% by Simpson's EF"}
              </span>
              <br />
              Diastolic Function:
              <span
                style={{
                  ...styles.diagnosisList,
                  color: parseInt(patient.DFADiagnosis) === 1 ? "red" : "green",
                }}
              >
                {" "}
                {patient.DFA}
              </span>
              <br />
            </>
          )}
          {/*         {parseInt(patient.abnDiagnosis) === 1
              ? patient.DFADiagnosis === 1
                ? "DFA: " +
                  patient.DFA +
                  " " +
                  (patient.LVMDiagnosis === 1 ? "| LVM: " + patient.LVM : "")
                : "LVM: " + patient.LVM
              : "Normal"}
*/}
          Comments: &nbsp;
          <span style={styles.diagnosisList}>{patient.Comments}</span>
        </ul>
      </Box>
    );
  };

  const handleClickViewPDF = () => {
    const baseURL = window.location.origin;

    setTimeout(() => {
      window.open(
        `${baseURL}/report/${patient.SeriesInstanceUID}`,
        "_blank",
        "toolbar=0,location=0,menubar=0"
      );
    }, 500);
  };

  async function handleLearnerClick() {
    // audit data for original
    let clinicData = {
      clinicName: user.attributes["custom:clinicName"],
    };

    const auditData = {
      accessType: "view",
      user: user.attributes.email,
      role: user.attributes["custom:role"],
      signatureName: user.attributes["custom:signatureName"],
      time: new Date(),
      clinic: clinicData,
    };

    // if the audit array exists, add the new auditData to it,
    // or else create an array with the new auditData
    const audit = patient.audit ? [...patient.audit, auditData] : [auditData];

    const bodyData = {
      //      ...patient,
      audit: audit,
      lastViewedByUser: {
        email: user.attributes.email,
        role: user.attributes["custom:role"],
        time: new Date(),
      },
    };

    await updateSeries(bodyData, patient.SeriesInstanceUID);

    // create copy
    const patientData = await getOneSeries(patient.SeriesInstanceUID);
    patientData.LearnerID = user.attributes["custom:learnerID"];
    patientData.studyStatus = "Test In Progress";
    patientData.SeriesInstanceUID =
      patient.SeriesInstanceUID +
      "Learner" +
      user.attributes["custom:learnerID"];
    // audit data for copy
    patientData.audit = [];
    patientData.audit.push(auditData);

    console.log("learnerclick", patientData);

    await createSeries(patientData);

    const updateReduxStore = async () => {
      // update redux store, this keeps the data fresh
      const patients = await fetchPatients();
      store.dispatch(loadPatients(patients));
    };

    updateReduxStore();
    const baseURL = window.location.origin;

    setTimeout(() => {
      window.open(
        `${baseURL}/patients/${
          patient.slug + "Learner" + user.attributes["custom:learnerID"]
        }`,
        "_self",
        "toolbar=0,location=0,menubar=0"
      );
    }, 500);
  }

  const CustomCardActions = () => {
    return (
      <Flex p="1rem" justify="space-between" align="center">
        <Button
          variant="ghost"
          onClick={() => {
            updateFavourites(
              removeOrAddItemFromArray(patient.slug, favourites)
            );
          }}
        >
          {favourite ? (
            <Icon as={FaHeart} w={5} h={5} color="red.400" />
          ) : (
            <Icon as={FaHeart} w={5} h={5} color="gray.300" />
          )}
        </Button>

        {user.attributes["custom:role"] === "Educator" &&
        patient.isGoldReport ? (
          <StarIcon w={5} h={5} color="yellow.400" />
        ) : (
          user.attributes["custom:role"] === "Educator" && (
            <StarIcon w={5} h={5} color="gray.400" />
          )
        )}
        {patient.studyStatus === "Signed" ? (
          <IconButton
            onClick={handleClickViewPDF}
            aria-label="Open PDF report"
            icon={<GrDocumentPdf />}
          />
        ) : null}
        {user.attributes["custom:role"] !== "Learner" ||
        patient.studyStatus === "Test In Progress" ? (
          <Button
            as={RouterLink}
            variant="ghost"
            color="purple.600"
            to={`/patients/${patient.slug}`}
            style={{
              textDecoration: "none",
              paddingRight: 0,
              fontSize: "15px",
            }}
          >
            OPEN{" "}
            {user.attributes["custom:role"] === "Learner" ? "TEST" : "REPORT"}
            <ChevronRightIcon w={10} h={10} />
          </Button>
        ) : (
          <Button
            variant="ghost"
            color="purple.600"
            onClick={handleLearnerClick}
            style={{
              textDecoration: "none",
              paddingRight: 0,
              fontSize: "15px",
            }}
          >
            TAKE TEST
            <ChevronRightIcon w={10} h={10} />
          </Button>
        )}
      </Flex>
    );
  };

  if (!patient) return null;

  if (displayStyle === "list") {
    return (
      <Grid
        w="70vw"
        m=".75rem auto"
        templateColumns="1fr 1fr 1fr"
        style={{
          ...styles.borderStyles,
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        }}
      >
        <CustomCardHeader />
        <CustomCardContent />
        <CustomCardActions />
      </Grid>
    );
  }

  return (
    <Flex
      direction="column"
      style={{
        ...styles.card,
        ...styles.borderStyles,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      }}
    >
      <CustomCardHeader />
      <CustomCardContent />
      <CustomCardActions />
    </Flex>
  );
};

const mapStateToProps = (state, ownProps) => ({
  favourite: isFavouriteSelector(state, ownProps),
  favourites: state.favourites,
  allGlasses: allGlassesSelector(state),
  patient: currentPatientSelector(state, ownProps),
});

const mapDispatchToProps = (dispatch) => ({
  updateFavourites: bindActionCreators(updateFavourites, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientItem);
