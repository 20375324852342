import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { capitalize, isEmpty } from "lodash";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  pdf,
} from "@react-pdf/renderer";
import { Box, Flex, Select } from "@chakra-ui/react";

import {
  sendFax,
  sendFaxTx,
  getOneSeries,
  getAllClinics,
  getAllReferringPhysicians,
} from "../services/form-api-service";
import { getCurrentAuthenticatedUser } from "../services/auth.service";
import {
  calculateAge,
  customDateFormat,
  customDateAndTimeFormat,
  getDateFromDateTimeString,
  getTimeFromDateTimeString,
  spaceForCaret,
  lastCommaFirst,
} from "../utils/util";

import { PrimaryButton, SecondaryButton } from "./Buttons.jsx";
import { Grid, Heading } from "@chakra-ui/react";

import imgBullseye from "../images/bullseye.png";

import EQI from "../images/EQI.png";

const ifEQI = process.env.REACT_APP_EQI;

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: "20pt",
    fontSize: "10pt",
    lineHeight: "1.2",
  },
});

const thinBorder = "1px solid black";
const thickBorder = "2px solid black";
const superThickBorder = "3px solid black";
const lightGrayBgColor = { backgroundColor: "#cccccc" };

const deployEnvironment = process.env.REACT_APP_DEPLOY_ENVIRONMENT;

// Create Document Component
const PDFGenerator = () => {
  const { slug: seriesInstanceId } = useParams();
  const [patient, setPatient] = useState({});
  const [faxResult, setFaxResult] = useState({});
  const [faxNumber, setFaxNumber] = useState("");
  const [clinics, setClinics] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const allClinics = await getAllClinics();

      setClinics(allClinics);
    };

    getData();
  }, []);

  const loadData = async () => {
    const patientData = await getOneSeries(seriesInstanceId);
    const referringPhysiciansData = await getAllReferringPhysicians();

    const referringPhysician = referringPhysiciansData.find(
      (rp) => rp.referringPhysicianId === patientData.ReferringPhysicianId
    )?.referringPhysicianName;

    if (referringPhysician) {
      setPatient({
        ...patientData,
        ReferringPhysicianName: referringPhysician,
      });
    } else {
      setPatient(patientData);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const refreshPDF = () => {
    loadData();
  };

  const sendFaxToAPI = async () => {
    const blob = await pdf(<PDFDocument patient={patient} />).toBlob();

    if (faxNumber === "") {
      setFaxResult({ error: "Please select a clinic to fax the report." });

      setTimeout(() => {
        setFaxResult({});
      }, 5000);

      return;
    }

    const pdfFile = new File([blob], `${patient.PatientName} Echo Report.pdf`);
    // console.log('pdfFile: ', pdfFile);
    const { attributes: userData } = await getCurrentAuthenticatedUser();

    const auditData = {
      user: userData.email,
      role: userData["custom:role"],
      time: new Date(),
      accessType: "fax",
    };

    const formData = new FormData();
    formData.append("file", pdfFile, `${patient.PatientName} Echo Report.pdf`);

    const patientData = JSON.stringify({
      ...patient,
      audit: [...patient.audit, auditData],
    });
    formData.append("patientData", patientData);
    // Add fax number as second argument
    formData.append("faxNumber", faxNumber);

    // const data = await sendFax(formData, seriesInstanceId);
    const data = await sendFaxTx(formData, seriesInstanceId);
    setFaxResult(data);

    setTimeout(() => {
      setFaxResult({});
    }, 5000);
  };

  return (
    <>
      <Flex margin="1rem" flexDirection="column">
        <Grid
          templateColumns="1fr 1fr"
          columnGap="1rem"
          width="40vw"
          marginBottom="2rem"
        >
          <Select
            type="select"
            name="faxNumber"
            width="25vw"
            onChange={(e) => setFaxNumber(e.target.value)}
          >
            <option value="">Select a clinic</option>
            {clinics.map((clinic) => (
              <option value={clinic.faxNumber} key={clinic.faxNumber}>
                {clinic.clinicName}
              </option>
            ))}
          </Select>
          <Box>
            <PrimaryButton onClick={sendFaxToAPI}>SEND AS FAX</PrimaryButton>
            &nbsp;&nbsp;
            {faxResult.message && (
              <p style={{ color: "blue" }}>{faxResult.message}</p>
            )}
            {faxResult.error && (
              <p style={{ color: "red" }}>{faxResult.error}</p>
            )}
          </Box>
        </Grid>

        <Grid
          width="40%"
          templateColumns="minmax(175px, auto) 1fr"
          columnGap="2rem"
          alignItems="center"
        >
          <SecondaryButton onClick={() => refreshPDF()}>
            REFRESH PDF
          </SecondaryButton>
          <PDFDownloadLink
            document={<PDFDocument patient={patient} />}
            fileName={`${patient.PatientName} Echo Report.pdf`}
          >
            {({ blob, url, loading, error }) => {
              return loading ? (
                <Heading as="h2" size="md">
                  Preparing report now...
                </Heading>
              ) : (
                <PrimaryButton>DOWNLOAD REPORT</PrimaryButton>
              );
            }}
          </PDFDownloadLink>
        </Grid>
      </Flex>
      <Viewer patient={patient} />
    </>
  );
};

const Viewer = ({ patient }) => {
  return (
    <div
      className="pdfViewer"
      style={{ height: "75vh", width: "99vw", margin: "0 auto" }}
    >
      <PDFViewer>
        <PDFDocument patient={patient} />
      </PDFViewer>
    </div>
  );
};

const getClinicDetails = (audit) => {
  const auditField = audit[audit.length - 1];

  if (auditField?.clinic) {
    const addressLine2 = `${auditField.clinic.city}  ${auditField.clinic.province} ${auditField.clinic.postalCode}`;
    return { ...auditField.clinic, addressLine2 };
  }

  return {};
};

const PDFDocument = ({ patient }) => {
  if (isEmpty(patient)) {
    return <Document></Document>;
  }

  const clinicDetails = getClinicDetails(patient.audit);
  const protocolName = patient.ProtocolName?.toLowerCase();
  const clinicLogo =
    deployEnvironment === "prime" && patient.clinicLocation === "hearthealth"
      ? process.env.REACT_APP_CLINICLOGO_URL2
      : process.env.REACT_APP_CLINICLOGO_URL;

  let studyType = "free";

  if (protocolName?.includes("stress")) {
    studyType = "exercise";
  } else if (protocolName?.includes("dobutamine")) {
    studyType = "dobutamine";
  }

  const reasonForTestValues = {
    other: patient.reasonForTestOther,
    murmur: "Murmur",
    stenosis: "Native Valvular Stenosis",
    regurgitation: "Native Valvular Regurgitation",
    mvp: "Known / Suspected MVP",
    congenital: "Congenital / Inherited Cardiac Structural Disease",
    prosthetic: "Prosthetic Valves",
    endocarditis: "Infective Endocarditis",
    pericardial: "Pericardial Disease",
    cardiacmass: "Cardiac Mass",
    interventional: "Interventional Procedures",
    pulmonary: "Pulmonary Disease",
    chestpain: "Chest Pain",
    cad: "CAD",
    dyspnea: "Dyspnea",
    edema: "Edema",
    cardiomyopathy: "Cardiomyopathy",
    hypertension: "Hypertension",
    thoracic: "Thoracic Aortic Disease",
    neurologic: "Neurologic / other embolic event",
    arrhythmia: "Arrhythmia",
    syncope: "Syncope",
    palpitations: "Palpitations",
    precardio: "Pre-Cardioversion",
    structural: "Suspected Structural Heart Disease",
    tee: "Indications for TEE",
    se: "Indications for SE",
  };

  const comparisonPriorsValues = {
    nopriors: "No prior studies available for comparison",
    other: patient.comparisonPriorsOther,
  };

  const studyQualityValues = {
    good: "Good",
    adequate: "Adequate",
    tds: "Technically Difficult Study (TDS)",
  };

  const tdsReasonValues = {
    bodyHabitus: "Body Habitus",
    diagnosticImagingNotObtainable: "Diagnostic imaging not obtainable",
    suboptimalAcousticWindow: "Suboptimal acoustic window",
    agitatedPatient: "Agitated patient",
  };

  const rhythmValues = {
    other: patient.otherRhythm,
    sinus: "Sinus",
    sinusTachy: "Sinus Tachycardia",
    sinusBrady: "Sinus Bradycardia",
    sinuswithbeats: "Sinus with extra beats",
    atrialFibrillation: "Atrial fibrillation",
    paced: "Paced",
    avPaced: "A-V Paced",
    vPaced: "V Paced",
    undetermined: "Undetermined",
    bradycardia: "Bradycardia",
    tachycardia: "Tachycardia",
    atrialFlutter: "Atrial flutter",
    freqAPB: "Frequent APBs",
    freqVPB: "Frequent VPBs",
    svtRuns: "SVT runs",
    ventricularEctopic: "Ventricular Ectopic",
    atrialEctopic: "Atrial Ectopic",
    wpw: "WPW",
    sss: "SSS",
    prolongedQT: "Prolonged QT",
  };

  const getReportTitle = () => {
    if (
      protocolName?.includes("dobutamine") &&
      patient.stressEchoType === "pharmacological"
    ) {
      return "DOBUTAMINE STRESS ECHOCARDIOGRAPHY REPORT";
    } else if (
      protocolName?.includes("stress") &&
      patient.stressEchoType === "physiological"
    ) {
      return "STRESS ECHOCARDIOGRAPHY REPORT";
    } else if (protocolName?.includes("pediatric")) {
      return "PEDIATRIC ECHOCARDIOGRAPHY REPORT";
    } else {
      return "TRANSTHORACIC ECHOCARDIOGRAPHY REPORT";
    }
  };

  const findingsFields = {
    free: [
      /*      { data: "studyQuality", name: "Study Quality" },*/

      {
        data:
          patient.studyQuality === "tds"
            ? patient.tdsReason === "other"
              ? "Technically Difficult Study - " + patient.otherTdsReason
              : "Technically Difficult Study - " +
                tdsReasonValues[patient.tdsReason]
            : studyQualityValues[patient.studyQuality],
        name: "Study Quality",
      },

      { data: patient.contrastAgentNotes, name: "Contrast Agent" },
      {
        data:
          patient.Rhythm === "other"
            ? patient.otherRhythm
            : rhythmValues[patient.Rhythm],
        name: "Rhythm",
      },
      {
        data: patient.lvSizeAndFunction,
        name: "Left Ventricle size and function",
      },
      { data: patient.rvPaPv, name: "Right Ventricle" },
      { data: patient.aortaAndAv, name: "Aorta and Pulmonic Valve" },
      { data: patient.mvAndTv, name: "Mitral Valve and Tricuspid Valve" },
      { data: patient.laAndRa, name: "Left Atrium and Right Atrium" },
      {
        data: patient.vessels,
        name: "Pulmonary Veins, Vessels, Pulmonary Artery, Septum, Pericardium, Mass/Device",
      },
    ],
    exercise: [
      {
        data:
          patient.studyQuality === "tds"
            ? patient.tdsReason === "other"
              ? "Technically Difficult Study - " + patient.otherTdsReason
              : "Technically Difficult Study - " +
                tdsReasonValues[patient.tdsReason]
            : studyQualityValues[patient.studyQuality],
        name: "Study Quality",
      },
      { data: patient.contrastAgentNotes, name: "Contrast Agent" },
      {
        data:
          patient.Rhythm === "other"
            ? patient.otherRhythm
            : rhythmValues[patient.Rhythm],
        name: "Rhythm",
      },

      // common stress echo fields
      { data: patient.stressTestProtocol, name: "Stress Test Protocol" },
      { data: patient.reasonForStopping, name: "Reason for Stopping" },
      { data: patient.functionalCapacity, name: "Functional Capacity" },
      {
        data: patient.hemodynamicExerciseResponse,
        name: "Hemodynamic Response to Exercise",
      },
      // Physiological stress echo fields
      { data: patient.baselineECG, name: "Resting ECG" },
      { data: patient.peakExerciseECG, name: "Peak Exercise ECG" },
      { data: patient.recoveryECG, name: "Recovery ECG" },
      { data: patient.restingStressEcho, name: "Resting Echo" },
      { data: patient.peakExerciseStressEcho, name: "Peak Exercise Echo" },
      { data: patient.recoveryStressEcho, name: "Recovery Echo" },
    ],
    dobutamine: [
      {
        data:
          patient.studyQuality === "tds"
            ? patient.tdsReason === "other"
              ? "Technically Difficult Study - " + patient.otherTdsReason
              : "Technically Difficult Study - " +
                tdsReasonValues[patient.tdsReason]
            : studyQualityValues[patient.studyQuality],
        name: "Study Quality",
      },
      { data: patient.pharmacologicalNotes, name: "Pharmacological Notes" },
      { data: patient.contrastAgentNotes, name: "Contrast Agent" },
      {
        data:
          patient.Rhythm === "other"
            ? patient.otherRhythm
            : rhythmValues[patient.Rhythm],
        name: "Rhythm",
      },
      // common stress echo fields
      { data: patient.stressTestProtocol, name: "Stress Test Protocol" },
      { data: patient.reasonForStopping, name: "Reason for Stopping" },
      { data: patient.functionalCapacity, name: "Functional Capacity" },
      {
        data: patient.hemodynamicExerciseResponse,
        name: "Hemodynamic Response to Exercise",
      },
      // Dobutamine (Pharmacological) stress echo fields
      { data: patient.restingECGEcho, name: "Resting ECG/Echo" },
      {
        data: patient.lowDoseDobutamineECGEcho,
        name: "Low Dose Dobutamine ECG/Echo",
      },
      {
        data: patient.peakDoseDobutamineECGEcho,
        name: "Peak Dose Dobutamine ECG/Echo",
      },
      { data: patient.recoveryECGEcho, name: "Recovery ECG/Echo" },
    ],
  };

  const Finding = ({ name, data }) => {
    if (name === "Contrast Agent" && !patient.isContrastAgentUsed) {
      return null;
    }

    if (
      name === "Pharmacological Notes" &&
      patient.stressEchoType === "physiological"
    ) {
      return null;
    }

    return (
      <View
        style={{
          flexDirection: "row",
          border: thinBorder,
          borderTop: 0,
        }}
      >
        <Text
          style={{
            width: "25%",
            borderRight: thinBorder,
            padding: "3pt",
            fontSize: "10pt",
          }}
        >
          {name}
        </Text>
        <Text style={{ width: "75%", padding: "3pt" }}>{data}</Text>
      </View>
    );
  };

  let isStressEcho = false;

  if (
    protocolName?.includes("dobutamine") &&
    patient.stressEchoType === "pharmacological"
  ) {
    isStressEcho = true;
  } else if (
    protocolName?.includes("stress") &&
    patient.stressEchoType === "physiological"
  ) {
    isStressEcho = true;
  }

  return (
    <Document title={`${spaceForCaret(patient.PatientName)} Echo Report.pdf`}>
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            border: thinBorder,
          }}
        >
          {ifEQI ? (
            <View
              style={{
                flexDirection: "column",
                width: "50%",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  borderRight: thinBorder,
                }}
              >
                {clinicDetails && (
                  <View
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "10pt",
                      paddingRight: "10pt",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      style={{ width: "100pt" }}
                      src={clinicLogo ? clinicLogo : clinicDetails.logo}
                    />
                  </View>
                )}
                {clinicDetails && (
                  <View
                    style={{
                      flexDirection: "column",
                      width: "50%",
                      justifyContent: "center",
                      paddingTop: "10pt",
                    }}
                  >
                    {deployEnvironment === "mkmed" ? (
                      <>
                        <Text>
                          MK Medical Specialists {"\n"}And Diagnostics Services
                        </Text>
                        <Text>Phone: {clinicDetails.phoneNumber}</Text>
                        <Text>Fax: {clinicDetails.faxNumber}</Text>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Text>{clinicDetails.clinicName}</Text>
                        <Text>{clinicDetails.street}</Text>
                        <Text>{clinicDetails.addressLine2}</Text>
                        <Text>Phone: {clinicDetails.phoneNumber}</Text>
                        <Text>Fax: {clinicDetails.faxNumber}</Text>
                      </>
                    )}
                  </View>
                )}
              </View>

              {clinicDetails && (
                <View
                  style={{
                    alignItems: "center",
                    borderRight: thinBorder,
                  }}
                >
                  <Image style={{ height: "66pt" }} src={EQI} />
                </View>
              )}
            </View>
          ) : (
            <View
              style={{
                width: "50%",
                padding: "10pt",
                flexDirection: "row",
                borderRight: thinBorder,
              }}
            >
              {clinicDetails && (
                <View
                  style={{
                    paddingRight: "10pt",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    style={{ width: "100pt" }}
                    src={clinicLogo ? clinicLogo : clinicDetails.logo}
                  />
                </View>
              )}
              {clinicDetails && (
                <View
                  style={{
                    flexDirection: "column",
                    width: "60%",
                    justifyContent: "center",
                  }}
                >
                  {deployEnvironment === "mkmed" ? (
                    <Text>
                      MK Medical Specialists {"\n"}And Diagnostics Services
                    </Text>
                  ) : (
                    <Text>{clinicDetails.clinicName}</Text>
                  )}

                  {patient.clinicLocation === "hearthealth" ? (
                    <>
                      <Text>106-3133 Sheppard Ave E,</Text>
                      <Text>Scarborough, ON, M1T 3J7</Text>
                      <Text>Phone: (416) 491-1555</Text>
                      <Text>Fax: (416) 491-4765</Text>
                    </>
                  ) : (
                    <>
                      <Text>{clinicDetails.street}</Text>
                      <Text>{clinicDetails.addressLine2}</Text>
                      <Text>Phone: {clinicDetails.phoneNumber}</Text>
                      <Text>Fax: {clinicDetails.faxNumber}</Text>
                    </>
                  )}
                </View>
              )}
            </View>
          )}

          <View style={{ flexDirection: "column", width: "50%" }}>
            <View
              style={{
                flexDirection: "row",
                borderBottom: thinBorder,
                padding: "10pt",
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text>
                  Study Date:{" "}
                  {customDateFormat(
                    !patient.SeriesDate ? patient.StudyDate : patient.SeriesDate
                  )}
                </Text>
                <Text>
                  Referring Physician:{" "}
                  {/* {spaceForCaret(patient.ReferringPhysicianName)} */}
                  {patient.ReferringPhysicianName}
                </Text>
                <Text>
                  Reading Physician: {spaceForCaret(patient.PhysiciansOfRecord)}
                </Text>
                <Text>Sonographer: {spaceForCaret(patient.OperatorsName)}</Text>
              </View>
            </View>

            <View style={{ flexDirection: "row", padding: "10pt" }}>
              <View style={{ flexDirection: "column", width: "100%" }}>
                <Text>
                  Patient Name:{" "}
                  {lastCommaFirst(spaceForCaret(patient.PatientName))}
                </Text>
                <Text>Patient Sex: {patient.PatientSex}</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>DOB: {customDateFormat(patient.PatientBirthDate)}</Text>
                  <Text>Age: {calculateAge(patient.PatientBirthDate)} </Text>
                  <Text>OHIP#: {patient.PatientID}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: "16pt",
            textAlign: "center",
            paddingLeft: "20pt auto",
            paddingRight: "20pt auto",
            paddingTop: "20pt auto",
            paddingBottom: "0pt",
          }}
        >
          {getReportTitle()}
        </Text>
        <Text
          style={{
            fontSize: "12pt",
            textAlign: "center",
            paddingBottom: "20pt auto",
          }}
        >
          (2D, Colour Doppler, M-Mode)
        </Text>
        <View style={{ border: superThickBorder }}>
          <View
            style={{
              minHeight: "1cm",
              flexDirection: "row",
              borderBottom: superThickBorder,
            }}
          >
            <Text
              style={{
                /*                width: "60%",
                borderRight: superThickBorder,
*/
                paddingLeft: "3pt",
              }}
            >
              Reason for Test:{" "}
              {patient.reasonForTest === "other"
                ? patient.reasonForTestOther
                : reasonForTestValues[patient.reasonForTest]}
            </Text>
            {/*            <Text style={{ width: "40%", paddingLeft: "3pt" }}>
              Study Quality: {/* {patient.studyQuality} */}
            {/*              
              {patient.studyQuality === "tds"
                ? patient.tdsReason === "other"
                  ? "Technically Difficult Study - " + patient.otherTdsReason
                  : "Technically Difficult Study - " +
                    tdsReasonValues[patient.tdsReason]
                : studyQualityValues[patient.studyQuality]}
            </Text>
*/}
          </View>
          <View
            style={{
              minHeight: "2cm",
              paddingLeft: "3pt",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text>
              {protocolName?.includes("dobutamine") &&
              patient.stressEchoType === "pharmacological"
                ? "Dobutamine Stress "
                : protocolName?.includes("stress") &&
                  patient.stressEchoType === "physiological"
                ? "Stress "
                : ""}
              Echo Summary:
            </Text>
            <Text>{patient.testConclusion}</Text>
            <br />
            <Text>
              {patient.comparisonPriors === "other"
                ? patient.comparisonPriorsOther
                : comparisonPriorsValues[patient.comparisonPriors]}
            </Text>
          </View>
        </View>

        {/* Findings */}
        <View wrap={false} style={{ paddingTop: "5pt" }}>
          {/* Border bottom is added to Findings to provide a top border the findings table below */}
          <Text style={{ borderBottom: thinBorder }}>Findings</Text>

          {findingsFields[studyType].map((f) => (
            /*            <Finding name={f.name} data={patient[f.data]} />*/
            <Finding key={f.name} name={f.name} data={f.data} />
          ))}
        </View>

        {/* Measurements */}
        <View
          wrap={false}
          style={{
            border: thickBorder,
            margin: "5pt 0 0",
            fontSize: "10pt",
          }}
        >
          <View
            style={{
              minHeight: "1.2cm",
              flexDirection: "row",
              borderBottom: thickBorder,
              justifyContent: "space-between",
            }}
          >
            <MeasurementsHeaderSection
              value="Patient Info"
              style={{ borderLeft: 0, ...lightGrayBgColor }}
            />
            <MeasurementsHeaderSection
              label="BSA"
              value={patient.BSA}
              uom={patient.BSAUOM}
            />
            <MeasurementsHeaderSection
              label="Height"
              value={patient.PatSize}
              uom={patient.PatSizeUOM}
            />
            <MeasurementsHeaderSection
              label="Weight"
              value={patient.PatWeight}
              uom={patient.PatWeightUOM}
            />
            <MeasurementsHeaderSection
              label="HR"
              value={patient.HR}
              uom={!patient.HRUOM ? (patient.HRUOM = "bpm") : patient.HRUOM}
            />
            <MeasurementsHeaderSection
              label="BP"
              value={patient.BPs + "/" + patient.BPd}
              uom="mmHg"
            />
          </View>

          {/* Measurements section one */}
          <View
            style={{
              flexDirection: "row",
              borderBottom: thickBorder,
              borderTop: 0,
            }}
          >
            <View
              style={{
                width: isStressEcho ? "100%" : "70%",
                borderRight: isStressEcho ? "" : thickBorder,
              }}
            >
              <MeasurementsHeading>
                SYSTOLIC FUNCTION AND CARDIAC STRUCTURE
              </MeasurementsHeading>
              <MeasurementsContainer>
                <MeasurementsColumn>
                  <Measurement
                    label="EF (Simpson's)"
                    value={patient.EFSimpsons}
                    uom={patient.EFSimpsonsUOM}
                    isAbnormal={patient?.abnormalValues?.EFSimpsons}
                  />
                  <Measurement
                    label="EF (Teicholz)"
                    value={patient.EFTeich}
                    uom={patient.EFTeichUOM}
                  />
                  <Measurement
                    label="TAPSE"
                    value={patient.TAPSE}
                    uom={patient.TAPSEUOM}
                    isAbnormal={patient?.abnormalValues?.TAPSE}
                  />
                  <Measurement
                    label="LV Mass"
                    value={patient.LVMass}
                    uom={patient.LVMassUOM}
                    isAbnormal={patient?.abnormalValues?.LVMass}
                  />
                  <Measurement
                    label="RV Base"
                    value={patient.RVB}
                    uom={patient.RVBUOM}
                    isAbnormal={patient?.abnormalValues?.RVB}
                  />
                </MeasurementsColumn>
                <MeasurementsColumn>
                  <Measurement
                    label="LVd"
                    value={patient.LVIDd}
                    uom={patient.LVIDdUOM}
                    isAbnormal={patient?.abnormalValues?.LVIDd}
                  />
                  <Measurement
                    label="RWT"
                    value={patient.RWT}
                    uom={patient.RWTUOM}
                  />
                  <Measurement
                    label="LVs"
                    value={patient.LVIDs}
                    uom={patient.LVIDsUOM}
                    isAbnormal={patient?.abnormalValues?.LVIDs}
                  />
                  <Measurement
                    label="RVd"
                    value={patient.RVIDd}
                    uom={patient.RVIDdUOM}
                    isAbnormal={patient?.abnormalValues?.RVIDd}
                  />
                  <Measurement
                    label="RV Mid"
                    value={patient.RVM}
                    uom={patient.RVMUOM}
                    isAbnormal={patient?.abnormalValues?.RVM}
                  />
                </MeasurementsColumn>
                <MeasurementsColumn>
                  <Measurement
                    label="IVS"
                    value={patient.IVSd}
                    uom={patient.IVSdUOM}
                    isAbnormal={patient?.abnormalValues?.IVSd}
                  />
                  <Measurement
                    label="PWd"
                    value={patient.PWd}
                    uom={patient.PWdUOM}
                    isAbnormal={patient?.abnormalValues?.PWd}
                  />
                  <Measurement
                    label="LV Mass Idx"
                    value={patient.LVMassIndex}
                    uom={patient.LVMassIndexUOM}
                    isAbnormal={patient?.abnormalValues?.LVMassIndex}
                  />
                  <Measurement
                    label="RV Length"
                    value={patient.RVL}
                    uom={patient.RVLUOM}
                    isAbnormal={patient?.abnormalValues?.RVL}
                  />
                </MeasurementsColumn>
                <MeasurementsColumn>
                  <Measurement
                    label="Ao Diam"
                    value={patient.AO}
                    uom={patient.AOUOM}
                    //                    isAbnormal={patient?.abnormalValues?.AO}
                  />
                  <Measurement
                    label="LA"
                    value={patient.LAd}
                    uom={patient.LAdUOM}
                    isAbnormal={patient?.abnormalValues?.LAd}
                  />
                  <Measurement
                    label="LA/AO"
                    value={patient.LAAO}
                    uom={patient.LAAOUOM}
                  />
                  <Measurement
                    label="RAVI"
                    value={patient.RAVI}
                    uom={patient.RAVIUOM}
                    isAbnormal={patient?.abnormalValues?.RAVI}
                  />
                </MeasurementsColumn>
                <MeasurementsColumn>
                  <Measurement
                    label="Asc Ao"
                    value={patient.AscAo}
                    uom={patient.AscAoUOM}
                    isAbnormal={patient?.abnormalValues?.AscAo}
                  />
                  <Measurement
                    label="LAVI"
                    value={patient.LAVI}
                    uom={patient.LAVIUOM}
                    isAbnormal={patient?.abnormalValues?.LAVI}
                  />
                  <Measurement label="GLS" value="" uom="" />
                </MeasurementsColumn>
              </MeasurementsContainer>
            </View>

            {!isStressEcho && (
              <View style={{ width: "30%" }}>
                <MeasurementsHeading>DIASTOLIC FUNCTION</MeasurementsHeading>
                <MeasurementsContainer>
                  <MeasurementsColumn>
                    <Measurement
                      label="E/A"
                      value={patient.MVEA}
                      uom={patient.MVEAUOM}
                    />
                    <Measurement
                      label="E"
                      value={patient.E}
                      uom={patient.EUOM}
                    />
                    <Measurement
                      label="A"
                      value={patient.A}
                      uom={patient.AUOM}
                    />
                    <Measurement
                      label="LAVI"
                      value={patient.LAVI}
                      uom={patient.LAVIUOM}
                    />
                    <Measurement label="Dec T." value="" uom="" />
                    <Measurement
                      label="s/d ratio"
                      value={patient.sdratio}
                      uom={patient.sdratioUOM}
                    />
                  </MeasurementsColumn>
                  <MeasurementsColumn>
                    <Measurement
                      label="E/e'"
                      value={patient.Ee}
                      uom={patient.EeUOM}
                    />
                    <Measurement
                      label="e' Sep"
                      value={patient.esep}
                      uom={patient.esepUOM}
                    />
                    <Measurement
                      label="e' Lat"
                      value={patient.elat}
                      uom={patient.elatUOM}
                    />
                    <Measurement
                      label="TR Vel"
                      value={patient.TRVel}
                      uom={patient.TRVelUOM}
                    />
                    <Measurement
                      label="IVRT"
                      value={patient.IVRT}
                      uom={patient.IVRTUOM}
                    />
                  </MeasurementsColumn>
                </MeasurementsContainer>
              </View>
            )}
          </View>
          {/* Measurements section one end */}

          {/* Measurements section two */}
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "70%",
                flexDirection: "row",
                borderRight: thickBorder,
              }}
            >
              <View style={{ flexDirection: "column", width: "100%" }}>
                <View style={{ width: "100%", borderBottom: thickBorder }}>
                  <MeasurementsHeading>AV</MeasurementsHeading>
                  <MeasurementsContainer>
                    <MeasurementsColumn>
                      <Measurement
                        label="AV Vel (max)"
                        value={patient.AVVmax}
                        uom={patient.AVVmaxUOM}
                      />
                      <Measurement
                        label="AV PG (max)"
                        value={patient.AVPG}
                        uom={patient.AVPGUOM}
                      />
                    </MeasurementsColumn>
                    <MeasurementsColumn>
                      <Measurement
                        label="AV Vel - (LVOT)"
                        value={patient.LVOTVmax}
                        uom={patient.LVOTVmaxUOM}
                      />
                      <Measurement
                        label="AV MG (max)"
                        value={patient.AVMG}
                        uom={patient.AVMGUOM}
                      />
                    </MeasurementsColumn>
                    <MeasurementsColumn>
                      <Measurement
                        label="DI"
                        value={patient.DI}
                        uom={patient.DIUOM}
                      />
                      <Measurement
                        label="LVOT"
                        value={patient.LVOTDIAM}
                        uom={patient.LVOTDIAMUOM}
                      />
                    </MeasurementsColumn>
                    <MeasurementsColumn>
                      <Measurement label="AVA" value="" uom="" />
                      <Measurement label="" value="" uom="" />
                    </MeasurementsColumn>
                    <MeasurementsColumn>
                      <Measurement label="AR PHT" value="" uom="" />
                      <Measurement label="" value="" uom="" />
                    </MeasurementsColumn>
                  </MeasurementsContainer>
                </View>
                <View>
                  <MeasurementsHeading>PV</MeasurementsHeading>
                  <MeasurementsContainer style={{ width: "45%" }}>
                    <MeasurementsColumn>
                      <Measurement
                        label="PV Vel"
                        value={patient.PVVel}
                        uom={patient.PVVelUOM}
                      />
                    </MeasurementsColumn>
                    <MeasurementsColumn>
                      <Measurement
                        label="PV PG"
                        value={patient.PVPG}
                        uom={patient.PVPGUOM}
                      />
                    </MeasurementsColumn>
                  </MeasurementsContainer>
                </View>
              </View>
            </View>
            <View style={{ width: "15%", borderRight: thickBorder }}>
              <MeasurementsHeading>TV</MeasurementsHeading>
              <MeasurementsContainer>
                <MeasurementsColumn>
                  <Measurement
                    label="TR PG"
                    value={patient.TRmaxPG}
                    uom={patient.TRmaxPGUOM}
                  />
                  <Measurement
                    label="RVSP"
                    value={patient.RVSP}
                    uom={patient.RVSPUOM}
                  />
                  <Measurement
                    label="RAP"
                    value={patient.RASP}
                    uom={patient.RASPUOM}
                  />
                  <Measurement
                    label="IVC"
                    value={patient.IVC}
                    uom={patient.IVCUOM}
                  />
                  <Measurement
                    label="PAP"
                    value={patient.PAP}
                    uom={patient.PAPUOM}
                  />
                </MeasurementsColumn>
              </MeasurementsContainer>
            </View>
            <View style={{ width: "15%" }}>
              <MeasurementsHeading>MV</MeasurementsHeading>
              <MeasurementsContainer>
                <MeasurementsColumn>
                  <Measurement
                    label="MV (PHT)"
                    value={patient.MVPHT}
                    uom={patient.MVPHTUOM}
                  />
                  <Measurement
                    label="MV trace (VTI)"
                    value={patient.MVTRCE}
                    uom={patient.MVTRCEUOM}
                  />
                  <Measurement
                    label="MV MR ERO"
                    value={patient.MVMRERO}
                    uom={patient.MVMREROUOM}
                  />

                  {/* Display the Diastolic measurements in the MV section */}
                  {isStressEcho && (
                    <>
                      <Measurement
                        label="E/A"
                        value={patient.MVEA}
                        uom={patient.MVEAUOM}
                      />
                      <Measurement
                        label="E"
                        value={patient.E}
                        uom={patient.EUOM}
                      />
                      <Measurement
                        label="A"
                        value={patient.A}
                        uom={patient.AUOM}
                      />
                      <Measurement
                        label="LAVI"
                        value={patient.LAVI}
                        uom={patient.LAVIUOM}
                      />
                      <Measurement label="Dec T." value="" uom="" />
                      <Measurement
                        label="s/d ratio"
                        value={patient.sdratio}
                        uom={patient.sdratioUOM}
                      />

                      <Measurement
                        label="E/e'"
                        value={patient.Ee}
                        uom={patient.EeUOM}
                      />
                      <Measurement
                        label="e' Sep"
                        value={patient.esep}
                        uom={patient.esepUOM}
                      />
                      <Measurement
                        label="e' Lat"
                        value={patient.elat}
                        uom={patient.elatUOM}
                      />
                      <Measurement
                        label="TR Vel"
                        value={patient.TRVel}
                        uom={patient.TRVelUOM}
                      />
                      <Measurement
                        label="IVRT"
                        value={patient.IVRT}
                        uom={patient.IVRTUOM}
                      />
                    </>
                  )}
                </MeasurementsColumn>
              </MeasurementsContainer>
            </View>
          </View>
          {/* Measurements section two end */}
        </View>

        {patient.isZScoreTableDisplayed && <ZScoreTable patient={patient} />}

        {patient.isBullsEyeTableDisplayed && <BullsEye patient={patient} />}

        <View>
          {/* Figure out business logic for Signing/Updates to the report and for difference between view/update by Sonographer */}
          <Text style={{ lineHeight: 1.6, paddingTop: "5pt" }}>
            {isAmended(patient.audit) ? "**REVISED DOCUMENT**  " : ""}Read and
            signed by:{" "}
            {/* {patient.lastModifiedByUser.role === "cardiologist" && */}
            {patient.studyStatus === "Signed" && (
              <Text>
                {getSignatureWithDateAndTime(
                  patient.audit,
                  patient.newSignedDate
                )}
                {/* {patient.lastModifiedByUser.email} [ */}
                {/* Dr. Ahmed Al Riyami MD FRCPC */}
                {/* [{capitalize(patient.lastModifiedByUser.role)}] */}
                {/* {` on`}{" "}
                {getDateFromDateTimeString(
                  patient.newSignedDate
                    ? patient.newSignedDate
                    : patient.lastModifiedByUser.time
                )}
                {` at`}{" "}
                {getTimeFromDateTimeString(
                  patient.newSignedDate
                    ? patient.newSignedDate
                    : patient.lastModifiedByUser.time
                )} */}
              </Text>
            )}
            {/* {patient.lastModifiedByUser.role === "sonographer" &&
              patient.studyStatus === "Signed" && ( */}
            {/* <Text>
                  {spaceForCaret(patient.OperatorsName).trim()} [
                  {capitalize(patient.lastModifiedByUser.role)}]{" "} */}
            {/* hardcoded the doctor's name - MKMed */}
            {/* on behalf of Dr. Ahmed Al Riyami MD FRCPC [Cardiologist] */}
            {/* on behalf of {patient.PhysiciansOfRecord} [Cardiologist] */}
            {/* {` on`}{" "} */}
            {/* {getDateFromDateTimeString(patient.lastModifiedByUser.time)} */}
            {/* {` at`}{" "} */}
            {/* {getTimeFromDateTimeString(patient.lastModifiedByUser.time)} */}
            {/* </Text> */}
            {/* )} */}
          </Text>
          {/* <Text>
        <Text>
            Sonographer:
              {getFirstSonographer(patient.audit)}
          </Text>
        </Text> */}
        </View>

        <View style={{ paddingTop: "3rem" }}>
          <View>
            {/* <AuditDataField data={patient.audit
              .filter((elem) => elem.accessType === "amend")} /> */}
            {patient.audit
              .filter((elem) => elem.accessType === "amend")
              .map((elem) => (
                <AuditDataField data={elem} />
              ))}
          </View>
        </View>

        {deployEnvironment !== "mkmed" && <NormalRanges />}
      </Page>
    </Document>
  );
};

const MeasurementsContainer = ({ children, style }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "3pt",
        ...style,
      }}
    >
      {children}
    </View>
  );
};

const MeasurementsHeading = ({ children }) => {
  return (
    <Text style={{ padding: "3pt", width: "100%", ...lightGrayBgColor }}>
      {children}
    </Text>
  );
};

const MeasurementsHeaderSection = ({ style, label, value, uom }) => {
  const UOMText = () => {
    return (
      <>
        <Text>
          {label} {value}
          {"\n"}
        </Text>
        <Text style={{ fontSize: "8pt" }}>{`(${uom})`}</Text>
      </>
    );
  };

  return (
    <Text
      style={{
        width: "100%",
        padding: "5pt 0 0 4pt",
        borderLeft: thickBorder,
        ...style,
      }}
    >
      {label ? uom ? <UOMText /> : label : value}
    </Text>
  );
};

const MeasurementsColumn = ({ children }) => {
  return (
    <View
      style={{
        fontSize: "8pt",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {children}
    </View>
  );
};

const Measurement = ({ label, uom, value, isAbnormal }) => {
  if (!value) {
    return null;
  }

  return (
    <Text style={{ padding: "0 0 5pt 2pt" }}>
      {label} {uom ? `(${uom})  ` : "  "}
      <Text style={{ color: isAbnormal ? "red" : "" }}>
        {value}
        {isAbnormal ? "*" : ""}
      </Text>
    </Text>
  );
};

const getFirstSonographer = (audit) => {
  const firstSonographer = audit.find(
    (element) =>
      element.accessType === "update" && element.role === "sonographer"
  );

  return firstSonographer && firstSonographer.user;
};

const isAmended = (audit) => {
  if (
    audit
      .slice()
      .reverse()
      .find((elem) => elem.accessType === "amend")
  ) {
    return true;
  }
  return false;
};

const getSignatureWithDateAndTime = (audit, newSignedDate) => {
  //https://thewebdev.info/2021/08/28/how-to-find-the-last-matching-object-in-array-of-objects-with-javascript/

  const lastSignedUser = audit
    .slice()
    .reverse()
    .find((elem) => elem.accessType === "sign");

  if (!lastSignedUser) {
    return "";
  }

  const signatureName = lastSignedUser?.signatureName
    ? lastSignedUser?.signatureName
    : "";

  const signedDateTime = newSignedDate ? newSignedDate : lastSignedUser?.time;
  const signedDate = getDateFromDateTimeString(signedDateTime);
  const signedTime = getTimeFromDateTimeString(signedDateTime);

  return signatureName + " on " + signedDate + " at " + signedTime;
};

const zScoreTableMeasurements = {
  // measurement property matches the measurement value API field
  column1: [
    { label: "RVED", measurement: "RVIDd" },
    { label: "IVS", measurement: "IVSd" },
    { label: "LVED", measurement: "LVIDd" },
    { label: "LVPW", measurement: "PWd" },
    { label: "LA", measurement: "LAd" },
    { label: "Aortic Valve", measurement: "AO" },
    { label: "MPA", measurement: "pMPA" },
    { label: "Pulmonary Valve", measurement: "pPV" },
    { label: "Mitral Valve", measurement: "pMV" },
    { label: "Tricuspid Valve", measurement: "pTV" },
  ],
  column2: [
    { label: "RPA prox", measurement: "pRPA" },
    { label: "LPA prox", measurement: "pLPA" },
    { label: "Aortic Sinus", measurement: "pAS" },
    { label: "Sinotubular Junction", measurement: "pSJ" },
    { label: "Ascending Aorta", measurement: "AscAo" },
    { label: "Transverse Arch", measurement: "pTRAR" },
    { label: "Isthmus", measurement: "pIST" },
    { label: "Descending Aorta", measurement: "pDscAo" },
  ],
};

const ZScoreTable = ({ patient }) => {
  return (
    <View style={{ width: "100%", flexDirection: "column" }}>
      <View style={{ width: "100%", flexDirection: "row", fontSize: "12pt" }}>
        <View
          style={{
            width: "50%",
            flexDirection: "row",
            border: thinBorder,
            padding: "1mm",
          }}
        >
          <Text style={{ width: "40%" }}></Text>
          <Text style={{ width: "30%" }}>Measurement</Text>
          <Text style={{ width: "30%" }}>Z-score</Text>
        </View>
        <View
          style={{
            width: "50%",
            flexDirection: "row",
            border: thinBorder,
            padding: "1mm",
          }}
        >
          <Text style={{ width: "40%" }}></Text>
          <Text style={{ width: "30%" }}>Measurement</Text>
          <Text style={{ width: "30%" }}>Z-score</Text>
        </View>
      </View>
      <View style={{ width: "100%", flexDirection: "row" }}>
        <View style={{ width: "50%", flexDirection: "column" }}>
          {zScoreTableMeasurements["column1"].map((elem) => (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                padding: "1mm",
                border: thinBorder,
              }}
            >
              <Text style={{ width: "40%" }}>{elem.label}</Text>
              <Text style={{ width: "30%" }}>
                {patient[elem.measurement]} {patient[elem.measurement + "UOM"]}
              </Text>
              <Text style={{ width: "30%" }}>
                {patient.zScore[elem.measurement]}
              </Text>
            </View>
          ))}
        </View>
        <View style={{ width: "50%", flexDirection: "column" }}>
          {zScoreTableMeasurements["column2"].map((elem) => (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                padding: "1mm",
                border: thinBorder,
              }}
            >
              <Text style={{ width: "40%" }}>{elem.label}</Text>
              <Text style={{ width: "30%" }}>
                {patient[elem.measurement]} {patient[elem.measurement + "UOM"]}
              </Text>
              <Text style={{ width: "30%" }}>
                {patient.zScore[elem.measurement]}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const normalRangesSplitByGender = [
  { label: "RVED", male: "<33 mm", female: "<33 mm" },
  { label: "LVIDd", male: "42-58 mm", female: "38-52 mm" },
  { label: "LVIDs", male: "25-40 mm", female: "22-35 mm" },
  { label: "IVSd", male: "6-10 mm", female: "6-9 mm" },
  { label: "PWd", male: "6-10 mm", female: "6-9 mm" },
  { label: "LV Mass (Idx)", male: "50-102 gr/m2", female: "44-88 gr/m2" },
  { label: "Sinuses of Valsalva", male: "<40 mm", female: "<36 mm" },
  { label: "Sinotubular Junction", male: "<35 mm", female: "<32 mm" },
  { label: "Prox. Ascending Aorta", male: "<38 mm", female: "<35 mm" },
];

const normalLVRangesSplitByGender = [
  { label: "Normal", male: "52 - 72%", female: "54 - 74%" },
  { label: "Mild", male: "41 - 51%", female: "41 - 53%" },
  { label: "Moderate", male: "30 - 40%", female: "30 - 40%" },
  { label: "Severe", male: "< 30%", female: "< 30%" },
];

const normalRangesCommon = [
  { label: "AV Max Vel", range: "1-1.7 m/s" },
  { label: "AV Max PG", range: "<12 mmHg" },
  { label: "AVA", range: ">2.5 cm2" },
  { label: "MV Max Vel", range: "0.6-1.3 m/s" },
  { label: "MVA", range: "4-6 cm2" },
  { label: "RVSP", range: "<40 mmHg" },
];

const rangeValueStyle = {
  width: "33.33%",
  borderRight: thickBorder,
  padding: "8pt",
  paddingLeft: "5pt",
};

const segmentValueStyle = {
  width: "33.33%",
  borderRight: thickBorder,
  padding: "4pt",
  paddingLeft: "5pt",
};

const normalRangeTableHeadingStyle = {
  width: "33.33%",
  borderRight: thickBorder,
  padding: "8pt",
  fontSize: "12pt",
};

const segmentTableHeadingStyle = {
  width: "33.33%",
  borderRight: thickBorder,
  padding: "4pt",
  fontSize: "10pt",
};

const NormalRanges = () => {
  return (
    <View wrap={false} style={{ width: "100%", marginTop: "20pt" }}>
      <Text style={{ fontSize: "12pt" }}>
        Reference Table for Cardiac Chamber Quantification in Adults
      </Text>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          border: thickBorder,
          borderBottom: thinBorder,
        }}
      >
        <View style={{ width: "60%", flexDirection: "column" }}>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              borderBottom: thickBorder,
            }}
          >
            <Text style={{ ...normalRangeTableHeadingStyle }}>Measurement</Text>
            <Text style={{ ...normalRangeTableHeadingStyle }}>
              Range (Male)
            </Text>
            <Text style={{ ...normalRangeTableHeadingStyle }}>
              Range (Female)
            </Text>
          </View>
          {normalRangesSplitByGender.map((elem) => (
            <View
              key={elem.label}
              style={{
                width: "100%",
                flexDirection: "row",
                borderBottom: thinBorder,
              }}
            >
              <Text style={{ ...rangeValueStyle, fontSize: "11pt" }}>
                {elem.label}
              </Text>
              <Text style={{ ...rangeValueStyle }}>{elem.male}</Text>
              <Text style={{ ...rangeValueStyle }}>{elem.female}</Text>
            </View>
          ))}
        </View>
        <View style={{ width: "40%", flexDirection: "column" }}>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              borderBottom: thickBorder,
            }}
          >
            <Text style={{ ...normalRangeTableHeadingStyle, width: "50%" }}>
              Measurement
            </Text>
            <Text
              style={{
                ...normalRangeTableHeadingStyle,
                width: "50%",
                borderRight: "none",
              }}
            >
              Range
            </Text>
          </View>
          {normalRangesCommon.map((elem) => (
            <View
              key={elem.label}
              style={{
                width: "100%",
                flexDirection: "row",
                borderBottom: thinBorder,
              }}
            >
              <Text
                style={{ ...rangeValueStyle, width: "50%", fontSize: "11pt" }}
              >
                {elem.label}
              </Text>
              <Text
                style={{
                  ...rangeValueStyle,
                  width: "50%",
                  borderRight: "none",
                }}
              >
                {elem.range}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

// measurement property matches the measurement value API field

const basalSegments = [
  { label: "1. Basal anterior", measurement: "basalAnterior" },
  { label: "2. Basal anteroseptal", measurement: "basalAnteroseptal" },
  { label: "3. Basal inferoseptal", measurement: "basalInferoseptal" },
  { label: "4. Basal inferior", measurement: "basalInferior" },
  { label: "5. Basal inferolateral", measurement: "basalInferolateral" },
  { label: "6. Basal anterolateral", measurement: "basalAnterolateral" },
];

const midCavitySegments = [
  { label: "7. Mid anterior", measurement: "midAnterior" },
  { label: "8. Mid anteroseptal", measurement: "midAnteroseptal" },
  { label: "9. Mid inferoseptal", measurement: "midInferoseptal" },
  { label: "10. Mid inferior", measurement: "midInferior" },
  { label: "11. Mid inferolateral", measurement: "midInferolateral" },
  { label: "12. Mid anterolateral", measurement: "midAnterolateral" },
];

const apicalSegments = [
  { label: "13. Apical anterior", measurement: "apicalAnterior" },
  { label: "14. Apical septal", measurement: "apicalSeptal" },
  { label: "15. Apical inferior", measurement: "apicalInferior" },
  { label: "16. Apical lateral", measurement: "apicalLateral" },
  { label: "17. Apex", measurement: "apex" },
  { label: "WMSI", measurement: "wmsi" },
];

// POST-EXERCISE measurement property matches the measurement value API field
const postbasalSegments = [
  { label: "1. Basal anterior", measurement: "postbasalAnterior" },
  { label: "2. Basal anteroseptal", measurement: "postbasalAnteroseptal" },
  { label: "3. Basal inferoseptal", measurement: "postbasalInferoseptal" },
  { label: "4. Basal inferior", measurement: "postbasalInferior" },
  { label: "5. Basal inferolateral", measurement: "postbasalInferolateral" },
  { label: "6. Basal anterolateral", measurement: "postbasalAnterolateral" },
];

const postmidCavitySegments = [
  { label: "7. Mid anterior", measurement: "postmidAnterior" },
  { label: "8. Mid anteroseptal", measurement: "postmidAnteroseptal" },
  { label: "9. Mid inferoseptal", measurement: "postmidInferoseptal" },
  { label: "10. Mid inferior", measurement: "postmidInferior" },
  { label: "11. Mid inferolateral", measurement: "postmidInferolateral" },
  { label: "12. Mid anterolateral", measurement: "postmidAnterolateral" },
];

const postapicalSegments = [
  { label: "13. Apical anterior", measurement: "postapicalAnterior" },
  { label: "14. Apical septal", measurement: "postapicalSeptal" },
  { label: "15. Apical inferior", measurement: "postapicalInferior" },
  { label: "16. Apical lateral", measurement: "postapicalLateral" },
  { label: "17. Apex", measurement: "postapex" },
  { label: "WMSI", measurement: "postwmsi" },
];

const BullsEye = ({ patient, isStressEcho }) => {
  return (
    <View
      wrap={false}
      style={{ width: "100%", marginTop: "20pt", marginBottom: "20pt" }}
    >
      <Text style={{ fontSize: "12pt" }}>
        Bull's Eye Plot of Segments and Coronary Arterial Territory
      </Text>

      <View
        wrap={false}
        style={{ width: "100%", marginTop: "5pt", flexDirection: "row" }}
      >
        <View
          style={{
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <Image
            style={{
              padding: "15pt",
              width: "30vw",
            }}
            src={imgBullseye}
          />
        </View>

        <View
          style={{
            flexDirection: "column",
            width: "70vw",
          }}
        >
          <View
            style={{
              borderTop: thickBorder,
              borderLeft: thickBorder,
              borderRight: thickBorder,
              padding: "5pt",
              fontSize: "10pt",
            }}
          >
            <Text
              style={{
                textAlign: "center",
              }}
            >
              RESTING
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              border: thickBorder,
              borderBottom: thinBorder,
            }}
          >
            <View style={{ width: "34%", flexDirection: "column" }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  borderBottom: thickBorder,
                }}
              >
                <Text style={{ ...segmentTableHeadingStyle, width: "100%" }}>
                  Basal segments
                </Text>
              </View>
              {basalSegments.map((elem) => (
                <View
                  key={elem.label}
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    borderBottom: thinBorder,
                  }}
                >
                  <Text style={{ ...segmentValueStyle, width: "80%" }}>
                    {elem.label}
                  </Text>
                  <Text
                    style={{
                      ...segmentValueStyle,
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    {patient[elem.measurement]}
                  </Text>
                </View>
              ))}
            </View>
            <View style={{ width: "33%", flexDirection: "column" }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  borderBottom: thickBorder,
                }}
              >
                <Text style={{ ...segmentTableHeadingStyle, width: "100%" }}>
                  Mid-Cavity segments
                </Text>
              </View>
              {midCavitySegments.map((elem) => (
                <View
                  key={elem.label}
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    borderBottom: thinBorder,
                  }}
                >
                  <Text style={{ ...segmentValueStyle, width: "80%" }}>
                    {elem.label}
                  </Text>
                  <Text
                    style={{
                      ...segmentValueStyle,
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    {patient[elem.measurement]}
                  </Text>
                </View>
              ))}
            </View>
            <View style={{ width: "33%", flexDirection: "column" }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  borderBottom: thickBorder,
                }}
              >
                <Text
                  style={{
                    ...segmentTableHeadingStyle,
                    width: "100%",
                    borderRight: "none",
                  }}
                >
                  Apical segments
                </Text>
              </View>
              {apicalSegments.map((elem) => (
                <View
                  key={elem.label}
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    borderBottom: thinBorder,
                  }}
                >
                  <Text style={{ ...segmentValueStyle, width: "80%" }}>
                    {elem.label}
                  </Text>
                  <Text
                    style={{
                      ...segmentValueStyle,
                      width: "20%",
                      textAlign: "center",
                      borderRight: "none",
                    }}
                  >
                    {patient[elem.measurement]}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          {isStressEcho && (
            <>
              <View
                style={{
                  marginTop: "10pt",
                  borderTop: thickBorder,
                  borderLeft: thickBorder,
                  borderRight: thickBorder,
                  padding: "5pt",
                  fontSize: "10pt",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                  }}
                >
                  POST-EXERCISE
                </Text>
              </View>

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  border: thickBorder,
                  borderBottom: thinBorder,
                }}
              >
                <View style={{ width: "34%", flexDirection: "column" }}>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      borderBottom: thickBorder,
                    }}
                  >
                    <Text
                      style={{ ...segmentTableHeadingStyle, width: "100%" }}
                    >
                      Basal segments
                    </Text>
                  </View>
                  {postbasalSegments.map((elem) => (
                    <View
                      key={elem.label}
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        borderBottom: thinBorder,
                      }}
                    >
                      <Text style={{ ...segmentValueStyle, width: "80%" }}>
                        {elem.label}
                      </Text>
                      <Text
                        style={{
                          ...segmentValueStyle,
                          textAlign: "center",
                          width: "20%",
                        }}
                      >
                        {patient[elem.measurement]}
                      </Text>
                    </View>
                  ))}
                </View>
                <View style={{ width: "33%", flexDirection: "column" }}>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      borderBottom: thickBorder,
                    }}
                  >
                    <Text
                      style={{ ...segmentTableHeadingStyle, width: "100%" }}
                    >
                      Mid-Cavity segments
                    </Text>
                  </View>
                  {postmidCavitySegments.map((elem) => (
                    <View
                      key={elem.label}
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        borderBottom: thinBorder,
                      }}
                    >
                      <Text style={{ ...segmentValueStyle, width: "80%" }}>
                        {elem.label}
                      </Text>
                      <Text
                        style={{
                          ...segmentValueStyle,
                          textAlign: "center",
                          width: "20%",
                        }}
                      >
                        {patient[elem.measurement]}
                      </Text>
                    </View>
                  ))}
                </View>
                <View style={{ width: "33%", flexDirection: "column" }}>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      borderBottom: thickBorder,
                    }}
                  >
                    <Text
                      style={{
                        ...segmentTableHeadingStyle,
                        width: "100%",
                        borderRight: "none",
                      }}
                    >
                      Apical segments
                    </Text>
                  </View>
                  {postapicalSegments.map((elem) => (
                    <View
                      key={elem.label}
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        borderBottom: thinBorder,
                      }}
                    >
                      <Text style={{ ...segmentValueStyle, width: "80%" }}>
                        {elem.label}
                      </Text>
                      <Text
                        style={{
                          ...segmentValueStyle,
                          width: "20%",
                          textAlign: "center",
                          borderRight: "none",
                        }}
                      >
                        {patient[elem.measurement]}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const NormalRangesMK = () => {
  return (
    <View wrap={false} style={{ width: "50%", marginTop: "20pt" }}>
      <Text style={{ fontSize: "12pt" }}>
        Left Ventricular Systolic Function (EF) - ASE, 2015
      </Text>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          borderLeft: thickBorder,
          borderTop: thickBorder,
          borderBottom: thinBorder,
        }}
      >
        <View style={{ width: "100%", flexDirection: "column" }}>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              borderBottom: thickBorder,
            }}
          >
            <Text style={{ ...normalRangeTableHeadingStyle }}></Text>
            <Text style={{ ...normalRangeTableHeadingStyle }}>Male</Text>
            <Text style={{ ...normalRangeTableHeadingStyle }}>Female</Text>
          </View>
          {normalLVRangesSplitByGender.map((elem) => (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                borderBottom: thinBorder,
              }}
            >
              <Text style={{ ...rangeValueStyle, fontSize: "11pt" }}>
                {elem.label}
              </Text>
              <Text style={{ ...rangeValueStyle }}>{elem.male}</Text>
              <Text style={{ ...rangeValueStyle }}>{elem.female}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const ChangeField = ({ changeKey, value }) => {
  if (
    typeof value.oldValue !== "string" ||
    typeof value.newValue !== "string"
  ) {
    return null;
  }

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: "30vw",
        justifyContent: "space-between",
      }}
    >
      <Text>{changeKey}</Text>
      <Text>{value.oldValue}</Text>
      <Text>{value.newValue}</Text>
    </View>
  );
};

const AuditDataField = ({ data }) => {
  const time = customDateAndTimeFormat(data.time);
  const text = `${capitalize(data.accessType)}ed by`;

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        borderRadius: "10px",
        boxShadow: data.changes ? "0 0 5px rgba(0, 0, 0, .2)" : "",
      }}
    >
      <Text>
        {text}: {data.user} [{capitalize(data.role)}] at {time}
      </Text>
      {/* {data.changes && <Text style={{ marginBottom: 0 }}>Changes</Text>} */}
      {data.changes && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "30vw",
            justifyContent: "space-between",
            marginBottom: 0,
          }}
        >
          <Text>Field</Text>
          <Text>Old value</Text>
          <Text>New value</Text>
        </View>
      )}
      {data.changes &&
        Object.keys(data.changes).map((element) => (
          <ChangeField changeKey={element} value={data.changes[element]} />
        ))}
    </View>
  );
};

export default PDFGenerator;
